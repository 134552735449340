<template>
  <div class="itemDetails item popUpScrollList" :id="containerId">
    <div v-if="deleted" class="element materialShadow">
      <ul>
        <li>
          <h2>{{ x('visitDeleted') }}</h2>
        </li>
      </ul>
    </div>
    <template v-else>
      <template v-if="item">
        <div class="element materialShadow">
          <ul>
            <li class="topImage">
            <span class="swipe_container">
              <div class="swipe_item" v-for="(picture, index) in item.pictures" :key="index">
                <div class="picture-container">
                  <img :src="config.imageBaseUrl + picture.name" @click="zoomImage(index)">
                  <span v-if="config.features[featureNames.bookmarks]"
                        @click.stop="bookmarkItem(index)" class="bookmark_button"
                        :class="{ hasBookmarked: isIndexBookmarked(index) }">
                    <icon :name="config.obj_texts.icon_bookmark"/>
                  </span>
                  <a v-if="config.features[featureNames.likes]" @click="itemLike()" class="like"
                     :class="{ hasLiked: item.Liked }">
                    <icon :name="likeClass"/>
                  </a>
                </div>
                <p v-if="!isEmpty(picture.comment)" class="image-comment">{{picture.comment}}</p>
                <div class="lsc-clear"></div>
              </div>
            </span>
            </li>
            <li v-if="item.pictures.length > 1" class="topPreviews">
              <div v-for="(picture, index) in item.pictures" :key="index" class="imageWrapper">
                <a @click='swipeToImage(index)'
                   :style="{ background: 'url(' + config.imageBaseUrl + picture.name + ')' }"/>
                <p class="imageNumber">{{ index + 1 }}</p>
              </div>
              <div class="lsc-clear"></div>
            </li>
            <li v-if="item.pictures.length === 1">
              <div class="lsc-clear"></div>
            </li>

            <!-- SCORING BAR -->
            <li v-if="config.features[featureNames.visitScore]"
                class="topActions ThemeFeedItem"
                :class="scoreClass"></li>

            <!-- -->
            <li class="topActions ThemeFeedItem">
              <span class="reaction"><icon :name="reactionIcon"/></span>
              <h2 v-if="item.reactionType === 'idea'">{{ item.title }}</h2>
              <h2 v-else><a @click="showCustomer">{{ customer.name }}</a></h2>
              <p>{{ city }}</p>
              <p class="customer-extra" v-if="customer.extra1 !== ''">{{ customer.extra1 }}</p>

              <span v-if="item.userRole !== 'external' &&
            item.reactionType !== 'idea' &&
            item.reactionType !== 'survey'">
              <h3>{{ x('type') }}</h3>
              <span v-for="campaign in item.campaigns" :key="campaign.id"
                    class='lsc-rounded blob ThemeFeedItemBubble'>
                {{ removeTrailingComma(campaign.name) }}</span>
              <h3>{{ x('products') }}</h3>
              <span v-for="product in item.products" :key="product.id"
                    class='lsc-rounded blob ThemeFeedItemBubble'>
                {{ removeTrailingComma(product.name) }}</span>
            </span>

              <span class="owner_comment">
              {{ item.comment }}
            </span>

              <span class="small"><icon name="fa-user"/>{{ username }}</span>
              <span class="small"><icon name="fa-calendar"/>{{ visitDate }}</span>
              <br>
              <span v-if="config.features[featureNames.comments]" class="small">
              <icon name="fa-commenting"/><b
                class="comment_count">{{ item.countComments }}</b>
            </span>
              <span v-if="config.features[featureNames.likes]" class="small">
              <icon name="fa-thumbs-up" @click="expandItemLikes=!expandItemLikes"/>
              <b class="like_count">{{ item.countLikes }}</b>
            </span>
              <span v-if="item.userRole==='external'" class="small userRoleIco">A</span>

              <item-like-list v-if="config.features[featureNames.likes]" :item-id="item.id"
                              :expand="expandItemLikes"/>
            </li>
          </ul>
        </div>

        <survey-details v-if="item.reactionType === 'survey'" :visit="item"/>

        <div class="element materialShadow">
          <template v-if="
            config.features[featureNames.reports] &&
            (
              item.userId === user.id ||
              user.backendDataAccess === true ||
              may(Permissions.orderOtherUsersReports)
            ) &&
            relevantReportTypes.length > 0 &&
            user.userRole === 'internal'">

            <button v-if="!isShowingReportUI"
                    @click="isShowingReportUI = true"
                    class="button lsc-button lsc-rounded sendReport">
              <icon name="fa-send"/> {{ x('orderReport')}}
            </button>

            <template v-if="isShowingReportUI">
              <div v-if="relevantReportTypes.length >= 2">
                <h1 class="reportRecipientsHeadline">Vælg rapport:</h1>
                <select class="lsc-input lsc-rounded select-report-type" v-model="selectedReportIndex">
                  <option v-for="(reportType, index) in relevantReportTypes"
                          :key="reportType.id"
                          :value="index">
                    {{ x(`rapportType.${reportType.id}`) }}
                  </option>
                </select>
              </div>

              <h2 class="reportRecipientsHeadline">{{ x('recipients') }}:</h2>
              <report-extra-recipients v-model="extraRecipients"
                                       :visit-ids="visitIds"
                                       :report-type-id="selectedReportType.id"/>
              <textarea v-if="showExtraRecipientsBox" class="reportRecipients lsc-rounded" v-model="emailRecipients" />
              <p v-if="reportRecipientsError" class="reportRecipientsError">
                {{ x('invalidEmails') }}
              </p>
              <p class="checkboxLine" v-if="showIncludeExcel">
                <checkbox v-model="includeExcel" /> {{x('includeExcel')}}
              </p>
              <p class="checkboxLine" v-if="showIncludeImages">
                <checkbox v-model="includeImages"/>
                {{x('includeImages')}}
              </p>
            </template>

            <button v-if="isShowingReportUI &&
              (item.userId === user.id || user.backendDataAccess === true) &&
              item.reactionType === 'survey'"
                    class="button lsc-button lsc-rounded sendReport"
                    @click="orderReport"
                    :disabled="
               this.isSendingReport === true ||
               this.didSendReport === true ||
               allRecipients.length === 0"
            >
              <template v-if="didSendReport">
                <icon name="fa-send"/> {{ x('reportOrdered') }}
              </template>
              <template v-else-if="isSendingReport">
                <icon name="fa-cog fa-spin"/> {{ x('orderingReportDots' )}}
              </template>
              <template v-else>
                <icon name="fa-send"/> {{ x('orderReport')}}
              </template>
            </button>
          </template>
          <a v-if="(item.userId === user.id || user.backendDataAccess === true) && isEditAndDeleteAllowed"
             @click="deleteItem" class='delete_visit button lsc-button lsc-rounded'
             :class="deleteButtonClass">
            <template v-if="deleteStatus === ''">
              <icon name='fa-trash'/>{{ x('deleteVisit') }}
            </template>
            <template v-if="deleteStatus === 'confirm'">
              <icon name='fa-exclamation-circle'/>{{ x('areYouSure') }}
            </template>
            <template v-if="deleteStatus === 'deleting'">
              <icon name='fa-cog fa-spin'/>${lang('deletingDots')}
            </template>
          </a>
          <a v-if="item.userId === user.id && config.mode==='app' && isEditAndDeleteAllowed"
             @click="editVisit" class='edit_visit button lsc-button lsc-rounded'>
            <icon name='fa-trash'/>
            {{ x('editVisit') }}
          </a>
        </div>

        <template v-if="config.features[featureNames.comments]">
          <div class="element materialShadow">
            <dl class="comments">
              <dd>
              <textarea class="lsc-input lsc-rounded-10"
                        :placeholder="x('commentDots')"
                        v-model="newCommentText"
                        maxlength="2000"/>
                <button class="lsc-button lsc-rounded-10 green bComment" @click="saveComment">
                  <template v-if="savingComment">
                    <icon name='fa-cog fa-spin'/>{{ x('savingDots') }}
                  </template>
                  <template v-else>
                    <icon name="fa-pencil"/>{{ x('saveComment') }}</template>
                </button>
              </dd>
              <item-comment v-for="comment in item.comments" :comment="comment" :key="comment.id"
                            @commentDeleted="item.countComments--"/>
            </dl>
            <div class="hotfix-air"></div>
          </div>
        </template>
      </template>

    </template>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState, mapGetters } from 'vuex';
  import { uniq, isEmpty } from 'lodash';
  import moment from 'moment';
  import { httpGet, httpDelete, httpPost } from '@/classes/httpHelper';
  import ImageSwipe from '@/classes/imageSwipe';
  import Events from '@/enums/event-names';
  import ComponentEventNames from '@/enums/component-event-names';
  import featureNames from '@/enums/feature-names';
  import scoreNames from '@/enums/score-names';
  import Checkbox from '@/components/Checkbox';
  import ReportExtraRecipients from '@/components/Pages/ReportExtraRecipients';
  import * as Email from '@/classes/email-helper';
  import EmailSources from '@/enums/email-sources';
  import ReportOptions from '@/enums/report-options';
  import Permissions from '@/enums/permissions';
  import { randomStringGenerator } from '@/classes/random-string-generator';
  import translate from '../Mixins/Translate';
  import May from '../Mixins/May';

  const ItemComment = defineAsyncComponent(() => import('./ItemComment'));
  const ItemLikeList = defineAsyncComponent(() => import('./ItemLikeList'));
  const SurveyDetails = defineAsyncComponent(() => import('../Survey/SurveyDetails'));

  /**
   * This shows details about an existing visit. It is shown when clicking on any visit in
   * App or Admin.
   */
  export default {
    name: 'item-details',
    props: {
      // The id of the item to be shown.
      id: {
        type: String,
        required: true,
      },
    },
    mixins: [translate, May],
    emits: [
      ComponentEventNames.didLoad,
      ComponentEventNames.close,
    ],
    components: {
      ReportExtraRecipients,
      Checkbox,
      'item-comment': ItemComment,
      'item-like-list': ItemLikeList,
      'survey-details': SurveyDetails,
    },
    data() {
      return {
        item: null,
        deleted: false,
        images: [],
        imageNames: [],
        deleteStatus: '',
        confirmTimer: null,
        expandItemLikes: false,
        featureNames,
        bookmarks: [],
        likeInProgress: false,
        likeClass: 'fa-thumbs-up',
        newCommentText: '',
        savingComment: false,
        isSendingReport: false,
        didSendReport: false,
        isShowingReportUI: false,
        emailRecipients: '',
        // List of recipients for the report-extra-recipients component
        extraRecipients: [],
        includeExcel: false,
        includeImages: false,
        reportRecipientsError: false,
        reportTimer: null,
        selectedReportIndex: 0,
        containerId: `item-details-${randomStringGenerator(8)}`,
        Permissions,
      };
    },
    computed: {
      customer() {
        // Fallback in case customer is missing
        return this.item.customer[0] || { name: '', city: '', postalCode: '' };
      },
      city() {
        return this.item.reactionType === 'idea' ? '' : `${this.customer.postalCode} ${this.customer.city}`;
      },
      reactionIcon() {
        switch (this.item.reactionType) {
          case 'rfi':
            return this.config.obj_texts.IconSmall_rfi || 'fa-frown-o';
          case 'idea':
            return this.config.obj_texts.IconSmall_idea || 'fa-lightbulb-o';
          case 'survey':
            return this.config.obj_texts.Icon_survey || 'fa-question-circle';
          default:
            return this.config.obj_texts.IconSmall_like || 'fa-smile-o';
        }
      },
      scoreClass() {
        if (this.config.features[featureNames.visitScore] === true) {
          switch (this.item.score) {
            case scoreNames.green: return 'green-card';
            case scoreNames.yellow: return 'yellow-card';
            case scoreNames.red: return 'red-card';
            default: return '';
          }
        }
        return '';
      },
      username() {
        return `${this.item.user[0].firstName} ${this.item.user[0].lastName}`;
      },
      visitDate() {
        return moment.utc(this.item.visitTime).local().calendar();
      },
      deleteButtonClass() {
        if (this.deleteStatus === 'confirm') {
          return 'red confirm';
        }
        return '';
      },
      visitIds() {
        return [this.item.id];
      },
      allRecipients() {
        let combinedRecipients = [...this.extraRecipients];

        const manualEmails = Email.cleanAndSplitEmailRecipients(this.emailRecipients);
        if (manualEmails.length > 0) {
          combinedRecipients = combinedRecipients.concat(manualEmails);
        }

        combinedRecipients = uniq(combinedRecipients);
        return combinedRecipients;
      },
      selectedReportType() {
        return this.relevantReportTypes[this.selectedReportIndex];
      },
      jobOptions() {
        return this.selectedReportType.jobOptions || [];
      },
      showIncludeExcel() {
        return this.jobOptions.includes(ReportOptions.includeExcel);
      },
      showIncludeImages() {
        return this.jobOptions.includes(ReportOptions.includeImages);
      },
      showExtraRecipientsBox() {
        return this.selectedReportType.emailOptions.includes(EmailSources.additional);
      },
      isEditAndDeleteAllowed() {
        // If the user is a service partner, the visit is only editable if it is in a current visit plan.
        if (this.user.userRole === 'internal') return true;
        if (!this.config.features[featureNames.visitPlans]) return true;

        const isInVisitPlan = this.$store.getters['VisitPlanStore/isVisitInVisitPlan'](this.item.id);
        console.log(`is in visit plan: ${isInVisitPlan}`);
        return isInVisitPlan;
      },
      ...mapState(['user', 'config']),
      ...mapGetters('ReportStore', {
        relevantReportTypes: 'getReportTypesForItemDetails',
      }),
    },
    async mounted() {
      this.registerEventHandlers();
      await this.load();
    },
    beforeUnmount() {
      this.unregisterEventHandlers();
      if (this.reportTimer) clearTimeout(this.reportTimer);
      if (this.confirmTimer) clearTimeout(this.confirmTimer);
    },
    methods: {
      registerEventHandlers() {
        this.$bus.on(Events.visitUpdated, this.onVisitUpdated);
        this.$bus.on(Events.visitLiked, this.onItemLiked);
        this.$bus.on(Events.visitUnliked, this.onItemUnliked);
        this.$bus.on(Events.customerUpdated, this.onCustomerUpdated);
      },
      unregisterEventHandlers() {
        this.$bus.off(Events.visitUpdated, this.onVisitUpdated);
        this.$bus.off(Events.visitLiked, this.onItemLiked);
        this.$bus.off(Events.visitUnliked, this.onItemUnliked);
        this.$bus.off(Events.customerUpdated, this.onCustomerUpdated);
      },
      onVisitUpdated(event) {
        const updatedVisit = event;
        if (this.item.id !== updatedVisit.id) return;

        this.item = updatedVisit;
        const idx = this.item.pictures.findIndex((p) => p.name === this.item.firstPictureName);
        if (idx > -1) {
          this.swipeToImage(idx);
        }
      },
      onItemLiked(event) {
        if (this.item.id === event.visit.id) {
          this.item.countLikes += 1;
        }
      },
      onItemUnliked(event) {
        if (this.item.id === event.visitId) {
          this.item.countLikes -= 1;
        }
      },
      onCustomerUpdated(customer) {
        if (customer.id !== this.item.customerId) return;
        // TODO: Check with vue3
        this.item.customer = [customer];
        // this.$set(this.item, 'customer', [customer]);
      },
      async load() {
        const itemArray = await httpGet(`visits/${this.id}`);
        this.item = itemArray[0];

        if (!this.item.pictures) {
          this.item.pictures = [];
        }

        if (this.item.bookmarks) {
          const a = this.item.bookmarks.split(',');
          this.bookmarks = a.map((x) => parseInt(x, 10));
        }

        this.$emit(ComponentEventNames.didLoad);

        this.deleted = this.checkIfDeleted();
        if (this.deleted) return;

        await this.$nextTick();
        window.itemSwipe = new ImageSwipe(`#${this.containerId} .swipe_container`);

        // If we have pictures (more than one), scroll to the right one
        if (this.item.pictures.length > 1) {
          await this.$nextTick();
          const coverImageIdx = this.item.pictures
            .findIndex((i) => this.item.firstPictureName === i.name);
          if (coverImageIdx > -1) {
            console.log('swiping to image', coverImageIdx);
            this.swipeToImage(coverImageIdx);
          }
        }
        if (this.item.pictures.length === 0) {
          console.warn('No pictures loaded for visit. Is this a manually created visit?');
        }
      },
      checkIfDeleted() {
        if (isEmpty(this.item.inactiveDate)) return false;

        const now = moment.utc();
        const inactiveDate = moment.utc(this.item.inactiveDate);
        return inactiveDate.isBefore(now);
      },
      async deleteItem() {
        switch (this.deleteStatus) {
          case '':
            this.showConfirm();
            break;
          case 'confirm':
            await this.performDelete();
            break;
          default:
            break;
        }
      },
      showReportUI() {
        this.isShowingReportUI = !this.isShowingReportUI;
      },
      async orderReport() {
        console.log('orderReport');
        if (this.isSendingReport || this.didSendReport) return;
        if (!this.isShowingReportUI) {
          this.showReportUI();
          return;
        }

        this.reportRecipientsError = !Email.validateEmails(this.emailRecipients);
        console.log('reportRecipientsError: ', this.reportRecipientsError);
        if (this.reportRecipientsError) return;

        const emailRecipientArray = this.allRecipients;
        const emailRecipients = Email.makeString(emailRecipientArray);

        this.isSendingReport = true;
        console.log('Email recipients: ', emailRecipients);
        const data = {
          emailRecipients,
          visitIds: [this.item.id],
          reportType: this.selectedReportType.id,
        };
        // These options should only be included if they are relevant for the selected report type.
        if (this.showIncludeExcel) {
          data.includeExcel = this.includeExcel;
        }
        if (this.showIncludeImages) {
          data.includeImages = this.includeImages;
        }
        await httpPost('orderreport', data);
        this.didSendReport = true;
        this.isSendingReport = false;
        this.isShowingReportUI = false;
        this.reportTimer = setTimeout(() => {
          this.closeReportUI();
        }, 3000);
      },
      closeReportUI() {
        this.didSendReport = false;
        this.isSendingReport = false;
        this.isShowingReportUI = false;
        this.emailRecipients = '';
        this.reportRecipientsError = '';
      },
      showConfirm() {
        this.deleteStatus = 'confirm';
        this.confirmTimer = setTimeout(() => {
          this.deleteStatus = '';
        }, 2000);
      },
      async performDelete() {
        clearTimeout(this.deleteStatus);

        await httpDelete(`visits/${this.item.id}`);
        this.$emit('close');
      },
      async itemLike() {
        // Skipping like because another is already in progress. Probably this is a double-click.
        if (this.likeInProgress === true) return;
        this.likeInProgress = true;

        this.likeClass = 'fa-cog fa-spin';
        await this.$nextTick();

        if (this.item.Liked === false
          || this.item.Liked === undefined
          || this.item.Liked === null) {
          console.log('Liking');
          // liking
          await httpPost(`visits/${this.item.id}/likes`, {});
          this.item.Liked = true;
        } else {
          console.log('Unliking');
          await httpDelete(`visits/${this.item.id}/likes`);
          this.item.Liked = false;
        }

        this.likeClass = 'fa-thumbs-up';
        this.likeInProgress = false;
      },

      /**
       * Saving a new comment
       * @returns {Promise<void>}
       */
      async saveComment() {
        const comment = this.newCommentText;
        let errCode = 0;

        if (comment.length > 2000) {
          errCode = 1;
          // eslint-disable-next-line no-alert
          alert(this.x('messageTooLong'));
        }

        if (comment !== '' && errCode === 0) {
          this.savingComment = true;

          const data = await httpPost(`visits/${this.item.id}/comments`, JSON.stringify({ comment }));

          this.newCommentText = '';
          const commentID = data.ok.message;
          this.savingComment = false;

          const commentData = {
            comment,
            firstName: this.item.user[0].firstName,
            lastName: this.item.user[0].lastName,
            userId: this.item.user[0].id,
            date: new Date(),
            id: commentID,
            visitId: this.item.id,
          };

          if (this.item.comments === undefined) {
            this.item.comments = [];
          }
          this.item.comments.push(commentData);
        }
      },

      async bookmarkItem(index) {
        console.log(`Bookmarking ${index}`);
        const pos = this.bookmarks.indexOf(index);
        if (pos !== -1) {
          this.bookmarks.splice(pos, 1);
        } else {
          this.bookmarks.push(index);
          this.bookmarks.sort();
        }

        if (this.bookmarks.length === 0) {
          await httpDelete(`visits/${this.item.id}/bookmark`);
        } else {
          const bookmarkData = this.bookmarks.join(',');
          await httpPost(`visits/${this.item.id}/bookmark`, { bookmarkData });
        }
      },
      isIndexBookmarked(index) {
        let found = false;
        this.bookmarks.forEach((b) => {
          if (b === index) {
            found = true;
          }
        });
        console.log(`isIndexBookmarked(${index})?`, found);
        return found;
      },
      removeTrailingComma(str) {
        return str.replace(/,\s*$/, '');
      },
      zoomImage(imageIndex) {
        const imageUrl = this.config.imageBaseUrl + this.item.pictures[imageIndex].name;
        this.$store.commit('setZoomImage', imageUrl);
      },
      swipeToImage(imageIndex) {
        console.log(`Swipe to image ${imageIndex}`);
        window.itemSwipe.goToImage(imageIndex + 1);
      },
      editVisit() {
        const component = this.item.reactionType === 'survey'
          ? defineAsyncComponent(() => import('../Survey/Survey'))
          : defineAsyncComponent(() => import('../EditVisit/EditVisit'));

        const title = this.item.reactionType === 'survey'
          ? this.x('editSurvey')
          : this.x('editVisit');

        const showSuffix = this.item.reactionType === 'survey';

        console.log('Edit visit');
        this.$store.commit('pushPopup', {
          component,
          title,
          showSuffix,
          direction: 'left',
          params: {
            loadedVisit: this.item,
          },
        });
      },
      showCustomer() {
        const { customer } = this;
        if (customer.id) {
          const CustomerPage = defineAsyncComponent(() => import('../Customer/CustomerPage'));
          this.$store.commit('pushPopup', {
            component: CustomerPage,
            title: customer.name,
            params: {
              customer,
            },
            direction: 'left',
          });
        }
      },
    },
  };
</script>

<style lang="scss">
 .itemDetails {
    button.sendReport {
      margin: 10px 0 0 0;
      width: 100%;
      text-align :center;
      border-bottom :2px solid #cecece;
      background :black;
    }

    .reportRecipientsHeadline {
      width: 100%;
      padding: 10px 10px 0 10px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      color: var(--ThemeTakePicH2Color);
    }

   select {
     width: 100%;
     margin-bottom: 10px;
   }

    .reportRecipients {
      width: 100%;
      border: 1px solid silver;
      padding: 10px;
      min-height: 120px;
    }

    .reportRecipientsError {
      color: red;
    }

    .recipientsInstructions {
      font-size: 12px;
      font-style: italic;
      color: #606060;
    }

    .customer-extra {
      margin-top: 10px;
      white-space: pre-line;
    }
  }

  .checkboxLine {
    margin: 10px 0 0 0;
  }

  .green-card {
    background: var(--ThemeVisitScoreGreen) !important;
  }

  .yellow-card {
    background: var(--ThemeVisitScoreYellow) !important;
  }

  .red-card {
    background: var(--ThemeVisitScoreRed) !important;
  }

  .picture-container {
    position: relative;
  }

  .imageWrapper {
    display: inline-block;
  }

  .imageNumber {
    text-align:center;
    color: #666666;
    font-size: 12px;
  }

  .image-comment {
    margin-bottom: 6px;
  }
</style>
